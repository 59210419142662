<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      fill-rule="evenodd"
      d="M4.317 3.978l-3.4 2.38A.583.583 0 0 1 0 5.88V1.12A.583.583 0 0 1 .918.642l3.4 2.38a.583.583 0 0 1 0 .956z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
